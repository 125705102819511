import React from 'react'
import './chatlist.styles.scss'
import { useNavigate } from 'react-router-dom'
import { IoCheckmark } from 'react-icons/io5'
import { url } from '../../config'
import { convertDateTime, currentChatTheme } from '../../utils/service'

const ChatList = ({userId, isGuide, userDetailsId, theme, username, fullName, nickName, image, message, lastMessageFrom, isActive, isRead, isReadByReceiver, unreadCount, timestamp}) => {
    const navigate = useNavigate()
    const clickHandler = async () => {
        let themeImage = currentChatTheme(theme)
        navigate('/Chat', {state:{isGuide, theme:themeImage, userId, userDetailsId, image:url + image, fullName, username, nickName}})
    }
    const {formattedTime} = convertDateTime(timestamp)
    message = message?.length > 40 ? message?.slice(0, 33) + '...': message
    return (
        <div className='chatlist-container'>
            <div onClick={()=>clickHandler()} className='d-flex'>
                <div className='position-relative'>
                    <img src={url + image} alt={username} />
                    {/* Badge */}
                    {isActive &&
                        <span className={`${isActive ? 'active-badge' : 'inactive-badge'} `}/>
                    }
                </div>

                <div className='d-flex column mt-5'>
                    <p className='dark-mode-text'>{fullName}</p>
                    <div className='d-flex items-center'>
                        {lastMessageFrom === username ? '' : <IoCheckmark size={16} color={isReadByReceiver ? 'var(--primary)' : 'gray'} style={{marginLeft:10, marginRight:-8}}/>}
                        {message?.includes('/media/chat_media/') ?
                            <div className='d-flex items-center'>
                                {/* <PiImageThin className='dark-mode-gray-text' style={{marginLeft:10}}/> */}
                                <img src={url + message} alt='' style={{marginLeft:10, borderRadius:0, height:20, width:20}}/>
                                <p style={{fontSize:13, marginLeft:4}} className='fw-400 dark-mode-gray-text'>sent an image</p>
                            </div>
                                
                        :
                        <span dangerouslySetInnerHTML={{ __html: message?.replace(/\n/g, '<br />') }}></span>
                        }
                    </div>
                </div>
            </div>
            <div className='d-flex items-center column'>
                {!isRead && unreadCount !== 0 && <p className='badge'>{unreadCount}</p>}
                <span>{formattedTime}</span>
            </div>
                
            
        </div>
    )
}

export default ChatList
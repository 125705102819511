import React, { useContext, useEffect, useState } from 'react'
import OtpComponent from '../OtpComponent'
import './userimagecard.styles.scss'
import ButtonComponent from '../Button/ButtonComponent'
import { PiPen } from 'react-icons/pi'
import { StateContext } from '../../context/stateContext'
import { convertDateTime } from '../../utils/service'

const RadioInput = ({text, onClick}) => {
  return(
    <div className='my-4 d-flex items-center'>
      <input onClick={onClick}  name='a' type='radio' />
      <p className='px-5'>{text}</p>
    </div>
  )
}

const UserImageCard = ({image, bookingDate, username, fullName, id, userId, userDetailsId, priceText, clearOtp, created, status, statusText, email, showModal, setShowModal, onOpenModal, isVerified, handleOtpSend, cancelHandler, booktext, onClick, editHandler, isPersonalizedTour}) => {
  const {userInfo} = useContext(StateContext)
  const [state, setState] = useState({
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:''
  })

  useEffect(()=>{
    if(clearOtp){
      setState({
        otp1:'',
        otp2:'',
        otp3:'',
        otp4:''
    })
    }
  }, [clearOtp])

  const [showOptions, setShowOptions] = useState(false)
  const [showText, setShowText] = useState(false)
  const [reasonText, setReasonText] = useState('Inappropriate')
  let statusMessage = status === null ? 'pending' : status ? 'completed' : 'cancelled'

  return (
    <>
      {/* modal code */}
      <div className='d-flex content-center items-center relative'>
        <div className={`modal-container-uic ${showModal ? '' : 'hide'}`}>
          {showOptions ?
          <>
            <div style={{marginTop:-20}} className='d-flex px-10'>
              <div>
                <RadioInput onClick={()=>{setShowText(false);setReasonText('Inappropriate')}} text='Inappropriate' />
                <RadioInput onClick={()=>{setShowText(false);setReasonText('Something 1')}} text='Something 1' />
                <RadioInput onClick={()=>{setShowText(false);setReasonText('Something 2')}} text='Something 2' />
                <RadioInput onClick={()=>{setShowText(false);setReasonText('Something 3')}} text='Something 3' />
                <RadioInput onClick={()=>{setShowText(true);setReasonText('')}} text='Other' />
              </div>
            </div>
            {showText &&
              <textarea placeholder='Type here...' value={reasonText} onChange={(e)=>setReasonText(e.target.value)} className='textarea-image-card' rows={8}></textarea>
            }
            <div className='d-flex w-100 space-between'>
              <ButtonComponent onClick={()=>setShowOptions(false)} buttonTextColor='black' wrapperContainerStyle={{width:"100%", margin:"10px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} title='Cancel' />
              <ButtonComponent onClick={()=>{cancelHandler(reasonText);setShowModal(false);setShowOptions(false);setReasonText('')}} wrapperContainerStyle={{width:"100%", margin:"10px"}} title='Send' />
            </div>
          </>
          :
          <>
            <p className='text-center fw-500'>Please enter OTP shared by tourist.</p>
            <OtpComponent state={state} setState={setState}/>
            <div className='d-flex w-100 space-between'>
              <ButtonComponent onClick={()=>setShowModal(false)} buttonTextColor='black' wrapperContainerStyle={{width:"100%", margin:"10px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} title='Cancel' />
              <ButtonComponent onClick={()=>handleOtpSend(state)} wrapperContainerStyle={{width:"100%", margin:"10px"}} title='Send' />
            </div>
            <p className='text-end fw-500 mt-4 px-10 text-lightgray' onClick={()=>setShowOptions(true)}>Cancel Tour ? Click Here.</p>
          </>
          }
        </div>
      </div>
      {/* image card code */}
      <div className='border-bottom my-2 d-flex cursor-pointer space-between items-center p-10 relative bg-lightgray'>
          <div onClick={onClick ? ()=>onClick(): !isVerified && statusMessage !== 'cancelled' ? () => onOpenModal(id) : ()=>true} className='d-flex items-center'>
            <div className='d-flex'>
                <img style={{height:50, width:50, borderRadius:"50%", marginRight:20, objectFit:"cover"}} src={image} alt={fullName} />
            </div>
            <div className='d-flex column'>
                <p className='fw-600 fs-14'>{fullName}</p>
                {created &&
                  <p className='fw-400 fs-12 text-lightgray'>{booktext} {convertDateTime(created)['customFormattedDate']}</p>
                }
                {bookingDate &&
                  <p className='fw-400 fs-12 text-lightgray'>Tour on {convertDateTime(bookingDate)['customFormattedDate']}</p>
                }
                {email &&
                  <p className='fw-400 fs-12'>{email}</p>
                }

            </div>
          </div>
          {priceText ?
            <p className={`fs-20 fw-600 ${priceText?.includes('+') ? 'accepted' : 'cancelled'}`}>{priceText}</p>
          :
            <div className='d-flex items-center'>
              <p className={statusText ? 'accepted' : statusMessage}>{statusText ? statusText : statusMessage}</p>
              {statusMessage === 'pending' && !userInfo?.isGuide &&
                <PiPen onClick={()=>editHandler(userId, id, isPersonalizedTour, image, fullName, userDetailsId, username)} color='gray' />
              }
            </div>
          }
      </div>
    </>
  )
}

export default UserImageCard
const isProd = true

const urlScheme = isProd ? 'https' : 'http'
const webSocketScheme = isProd ? 'wss' : 'ws'
let domain = isProd ? 'api.city2guide.com' : '127.0.0.1:8000'

export const url = `${urlScheme}://${domain}`

if(isProd){
    domain = domain + ':8000'
}

export const websocketurl = `${webSocketScheme}://${domain}`
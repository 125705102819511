import React, { useContext, useEffect, useState } from 'react'
import { identityVerification } from '../../assets/images'
import Wrapper from './Wrapper'
import { PiInfoBold, PiLightbulb } from 'react-icons/pi'
import { FileUpload} from '../../components';
import { StateContext } from '../../context/stateContext';
import { useNotify } from '../../components/Toast';
import { getUserDocuments } from '../../utils/service';
import { url } from '../../config';

const TouristIdentityVerification = () => {
    const {identityVerificationForm, setIdentityVerificationForm} = useContext(StateContext)
    const [documents, setDocuments] = useState({})
    const changeHandler = (key, text) => {
      if (text && typeof text === 'object' && text instanceof Blob) {
          setIdentityVerificationForm({...identityVerificationForm, [key]:text})
      }
      setIdentityVerificationForm({...identityVerificationForm, [key]:text})
    }

    const { notify } = useNotify()


    useEffect(()=>{
      getUserDocuments().then(res=> {
        setDocuments(res.data)
      }).catch(err => notify(err.response && err.response.data.detail ? err.response.data.detail : err.message))
      // eslint-disable-next-line
    },[])
  return (
    <Wrapper image={identityVerification} slideIndex={0} lineLength={1} title='Identity Verification' nextButtonText="Submit" nextButtonUrl='/' submit={true} identityHandler={true}>
    <div className='bg-lightgray border-rounded p-20 d-flex space-between items-center'>
        <PiLightbulb size={50} className='me-2'/>
        <p className='fs-12'>Any updated identity proof with "Full Name" & "Residential Address" on same page.<br/>
        <b>example</b> : Driver Licence, Identification Card, Permanent Resident Card, Passport, etc.
        </p>
    </div>
    <FileUpload isVerified={documents?.isVerified} value={url + documents?.docFront} onChange={(e)=>changeHandler('docFront', e.target.files[0])}/>
    <FileUpload isVerified={documents?.isVerified} value={url + documents?.docBack} side='Back'  onChange={(e)=>changeHandler('docBack', e.target.files[0])}/>
    <div className='d-flex flex-end'>
      <p className='fs-14 me-2 dark-mode-text'>Add a watermark</p>
      <input type='checkbox' />
    </div>

    <br />
    <div className='bg-crimsonLight border-rounded p-20 d-flex space-between items-center'>
      <PiInfoBold size={80} color='crimson' className='me-2'/>
        <p className='fs-12 dark-mode-text'>
            Uploading incorrect or morphed document is a serious offence of Forgenry under Indian Penal Code of IT Act, 2000
            (IPC Section 66C & 66D).
            It may lead to permanent ban from City2Guide Platform.
        </p>
    </div>
    <div className='d-flex flex-start items-center my-10'>
      <input className='me-2' type='checkbox' />
      <p className='fs-14 dark-mode-text'>I acknowledge all the information shared above are true and correct.</p>
    </div>
    <div className='d-flex flex-start items-center my-10'>
      <input className='me-2' type='checkbox' />
      <p className='fs-14 dark-mode-text'>I agree to obey the policy of city2guide.</p>
    </div>
    <br/>
    </Wrapper>
  )
}

export default TouristIdentityVerification
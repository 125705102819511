import React from 'react'
import './searchfilterinput.styles.scss'
import { FcFilledFilter } from 'react-icons/fc'
import { IoSearch } from 'react-icons/io5'

const SearchFilterInput = ({onFocus, showSearchIcon=false, showText=false, onClick=()=>true, searchHandler=()=>true, ...props}) => {

  return (
    <div className='search-filter-container'>
        <input {...props} onFocus={onFocus} placeholder='Enter the city to search a guide' />
        <div className='d-flex items-center icons-container'>
          {showSearchIcon &&
            <IoSearch onClick={()=>searchHandler()} size={26} color='gray' />
          }
          {showText ?
              <p className='text-primary fw-500'>Search</p>
            :
            <FcFilledFilter onClick={onClick} size={30} color='var(--primary)'/>
          }
        </div>
    </div>
  )
}

export default SearchFilterInput
import React, { useContext } from 'react'
import {BackButton, ButtonComponent} from '../../components'
import { support } from '../../assets/images'
import { PiMagnifyingGlass } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { StateContext } from '../../context/stateContext'

const SupportPage = () => {
  const navigate = useNavigate()
  const {darkMode} = useContext(StateContext)
  return (
    <div className={`${darkMode ? 'dark-mode' : ""}`}>
      <BackButton size={24} text='Support' />
      <div className='p-20'>
          {/* header : back, title */}

          <div className='d-flex space-evenly'>
              <img style={{marginTop:-40}} src={support} alt='support city2guide' />
          </div>
              
          <div className='p-10 bg-lightgray border-rounded d-flex items-center'>
              <PiMagnifyingGlass />
              <input className='text-lightgray px-20 bg-transparent border-none' placeholder='How we can help you ?'></input>
          </div>

          <p className='my-20 dark-mode-text'>Search from our vast and informational documentations </p>

          <h3 className='fw-500 my-10 dark-mode-text'>Quick Links</h3>

          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray'>Customer Care</p>
          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray'>Do's And Dont's</p>
          <p onClick={()=>navigate('/ContactUs')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray'>Emergency Helpline</p>
          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray'>Privacy Policy</p>
          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray'>Report</p>
          <p onClick={()=>navigate('/Terms-Conditions')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray'>Terms & Conditions</p>

          <br/>
          
          <p className='dark-mode-text'>If the issue still persists , </p>
          {/* <ButtonComponent title='Call Us' wrapperContainerStyle={{margin:'20px 0px 10px 0px'}}/> */}
          <ButtonComponent wrapperContainerStyle={{margin:"30px 0px"}} onClick={()=>navigate('/ContactUs')} title='Write to Us'/>
      </div>
    </div>
  )
}

export default SupportPage
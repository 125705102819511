import React, { useContext, useState } from 'react'
import { BackButton } from '../../components'
import { PiCaretRight, PiMapPin, PiMapPinFill, PiPaperPlane, PiPaperPlaneFill, PiPhone } from 'react-icons/pi'
import { CiMail } from 'react-icons/ci'
import { location } from '../../assets/images'
import {Facebook, Google, Instagram, Linkedin} from '../../assets/icons'
import { StateContext } from '../../context/stateContext'

const IconText = ({Icon, text}) => {
  return(
    <div className='my-10 border d-flex space-between items-center border-rounded p-10'>
      <div className='d-flex items-center'>
        <Icon size={22}/>
        <p className='px-10'>{text}</p>
      </div>
      <PiCaretRight />
    </div>
  )
}

const ContactUsPage = () => {
  
  const [showMap, setShowMap] = useState(false)
  const { darkMode } = useContext(StateContext)

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div className='my-20 px-10'>
        {/* header : back, title */}
        <div className='d-flex space-between items-center'>
          <BackButton size={24}/>
          <p style={{marginLeft:-70, fontSize:'26px', fontWeight:"600"}}>Contact Us</p>
          <p />

        </div>
        <div className='d-flex space-between items-center my-20'>
          <div onClick={()=>setShowMap(!showMap)} className={`d-flex content-center items-center shadow`} style={{background:"#ece8e8", height:40, width:40,  marginBottom:showMap ?  0 : -50, borderRadius:"30%",}}>
            {showMap ? 
            <PiPaperPlane color={`black`} style={{transform:"rotate(48deg)", marginLeft:6, marginBottom:6}} size={28}/>
            :
            <PiPaperPlaneFill color={`var(--primary)`} style={{transform:"rotate(48deg)", marginLeft:6, marginBottom:6}} size={30}/>
            }
            
          </div>
          <div onClick={()=>setShowMap(!showMap)} className={`d-flex content-center items-center shadow`} style={{background:"#ece8e8", height:40, width:40, marginBottom:showMap ? -50 : 0, borderRadius:"30%",}}>
            {showMap ?
              <PiMapPinFill color={`var(--primary)`} size={30}/>
              :
              <PiMapPin color={`black`} size={28}/>

            }
          </div>
        </div>
        <div className={`bg-lightgray p-10 border-rounded ${showMap ? 'hide' :''}`}>
          <h2 className='fw-500'>Get In Touch</h2>
          <p>Call us on our 24x7 active toll free numbers</p>

          <IconText Icon={PiPhone} text='91XXXXXXXXX'/>
          <IconText Icon={PiPhone} text='91XXXXXXXXX'/>
          <IconText Icon={PiPhone} text='91XXXXXXXXX'/>

          <p className='my-20 px-10'>Write to us and get guaranteed response within 24 hours</p>

          <IconText Icon={CiMail} text='support@city2guide.com'/>
          <IconText Icon={CiMail} text='support@city2guide.com'/>

        </div>
        <div className={`bg-lightgray p-10 border-rounded ${showMap ? '' :'hide'}`}>
          <h2 className='fw-500'>Reach to Us</h2>
          <img style={{width:"100%"}} src={location} alt='address' />
        </div>

        <div className='d-flex w-100'>
              <div className='d-flex p-10 my-20 items-center space-between bg-lightgray border-rounded shadow' style={{width:"90%", bottom:20, position:"absolute"}}>
                  <img className='cursor-pointer' onClick={()=>window.open('https://www.google.com/search?q=city2guide&rlz=1C1RXQR_enIN1025IN1025&oq=city2guide&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIKCAEQABiABBiiBDIKCAIQABiABBiiBDIKCAMQABiABBiiBDIKCAQQABiABBiiBDIGCAUQRRg9MgYIBhBFGD0yBggHEEUYPNIBCDMxNzdqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8')} style={{height:33, width:33, borderRadius:'50%'}} src={Google} alt='Google' />
                  <img className='cursor-pointer' style={{height:40, width:40, borderRadius:'50%'}} src={Facebook} alt='facebook' />
                  <img className='cursor-pointer' style={{height:40, width:40, borderRadius:'50%'}} src={Instagram} alt='Instagram' />
                  <img className='cursor-pointer' style={{height:40, width:40, borderRadius:'50%'}} src={Linkedin} alt='Linkedin' />
              </div>
        </div>

      </div>
    </div>
  )
}

export default ContactUsPage
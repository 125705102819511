import React, { useContext } from 'react'
import { BsX } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent } from '../../components'
import { createGuide, storeIdentityVerification } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'
import { IndiaGateImage } from '../../assets/images'

const Wrapper = ({image, slideIndex, title, children, descText, nextButtonText, nextButtonUrl, identityHandler, submit=false, lineLength=4}) => {
  const navigate = useNavigate()
  const {darkMode, userForm, identityVerificationForm} = useContext(StateContext)
  const { notify } = useNotify()

  const isValidPassword = (password) =>{
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password)
  }

  function validateUsername(username) {
      const regex = /^[a-zA-Z0-9_]+$/;
      if (!regex.test(username)) {
        return true
  }
}

  const submitHandler = () => {
    if(!submit){
      // if we are navigating from identiy verification screen to bank detail screen first check if the adhaar card and pan card are valid
      if(nextButtonUrl === '/Profile-Verification/bank-details-verification'){
        let adhaarRegex = /^\d{4}\s?\d{4}\s?\d{4}$/
        let panCardRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/
        if(!adhaarRegex.test(identityVerificationForm?.adhaarCard) || identityVerificationForm?.adhaarCard?.length < 12){
          return notify('please enter valid adhaar card number')
        }
        if(!panCardRegex.test(identityVerificationForm?.panCard) || identityVerificationForm?.panCard?.length < 10){
          return notify('please enter valid pan card number')
        }
      }
      return navigate(nextButtonUrl)
    }

    if(identityHandler){
      storeIdentityVerification(identityVerificationForm).then(res => {
        notify('Form Submitted 🚀, Redirecting...')
        setTimeout(()=>{
          navigate(nextButtonUrl)
        }, 2000)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
      return
    }

    

    if(userForm?.contact.length < 10 || userForm?.email.length < 10){
      notify('Please complete contact verification process.')
      setTimeout(()=>{
        navigate('/Verification-wizard/contact-verification')
      }, 1000)
      return
    }

    if(userForm?.username.length < 5){
      return notify('please write a valid username.')
    }
    if(validateUsername(userForm?.username)){
      return notify('please write a valid username.')
    }
    if(!isValidPassword(userForm?.password)){
      return notify('Please make sure password meet all the requirements.')
    }

    createGuide(userForm).then(res => {
      notify('Account Created 🚀, Redirecting...')
      setTimeout(()=>{
        navigate(nextButtonUrl)
      }, 2000)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  return (
    <div className={`${darkMode ? 'dark-mode' : ""} contact-verification-container`}>
    
      <div className='responsive-image-container'>
        {/* img */}
        <img className='contact-card' src={image} alt='Personalize your account' />
        <img className='indiaGate' src={IndiaGateImage} alt='India Gate' />
      </div>

      <div className='responsive-content-container'>
        {/* close icon */}
        <BsX onClick={()=>navigate('/Signup')} className='close-icon'/>
        
        {/* sliding lines */}
        <div className='sliding-lines-container'>
            {Array.from(Array(lineLength).keys()).map(number => {
                return(
                    <div key={number} className='sliding-line'>
                        {number < slideIndex ?
                            <span className='active' style={{width:'100%'}} />
                            :
                            <span className='active' style={{width: number === slideIndex ? '10%' : '0%'}} />
                        }
                    </div>
                )
            })}
        </div>

        {/* Personal Information : text */}
        <h1 className='verification-text'>{title}</h1>
        {descText &&
          <p className='desc-text'>{descText}</p>
        }
        {children}
        

        {/* back & save and continue buttton */}
        <div className='back-save-buttons-container'>
          <ButtonComponent onClick={()=>navigate(-1)} title={'Back'} wrapperContainerStyle={{width:"35%", backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)", opacity:0.9}} buttonTextColor='black' />
          <ButtonComponent onClick={()=>submitHandler()} title={nextButtonText} wrapperContainerStyle={{width:"45%"}}/>
        </div>
      </div>

  </div>
  )
}

export default Wrapper
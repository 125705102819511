import React, { useCallback, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StateContext } from '../../context/stateContext';  // Import the context

export const useNotify = () => {
  const { darkMode } = useContext(StateContext);  // Access darkMode from context
  // Use darkMode to customize the notify call
  const notify = useCallback((message, color = 'black', position = 'top-left') => {
    const id = toast(`${message}`, {
      position,
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: darkMode ? "dark" : "light",  // Set theme based on darkMode
      style: {
        textAlign: "center",
        color: darkMode ? 'white' : color,  // Change text color for dark mode
        margin: "0px 5px",
      },
    });

    // update the notification if darkMode changes during its lifecycle
    toast.update(id, {
      theme: darkMode ? "dark" : "light",
    });
  }, [darkMode]);

  return { notify };  // Return notify function to be used
};

// Component for toast notifications
function Toast() {
  return <ToastContainer />;
}

export default Toast;

import React from 'react';
import './circularprogress.styles.scss';

const CircularProgress = ({ percentage, image, fullName }) => {
  const radius = 55;
  const stroke = 3;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const angle = (percentage / 100) * 360 ; // Calculate the angle
  const x = radius  + normalizedRadius * Math.cos((angle * Math.PI) / 180); // Calculate x position
  const y = radius  + normalizedRadius * Math.sin((angle * Math.PI) / 180); // Calculate y position

  return (
    <div className="circular-progress">
      <svg
        height={radius * 2}
        width={radius * 2}
      >
        <circle
          stroke="lightgray"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={percentage === 100 ? 'green' : "tomato"}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          strokeLinecap="round"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <text x={percentage <= 40 ? x -  6 : percentage < 60 ? x + 10 : x-5} y={percentage < 45 ? y + 5 : y} textAnchor="middle" dominantBaseline="middle" className="percentage-label">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{percentage !== 100 && percentage + '%'}
        </text>
      </svg>
      <img src={image} alt={fullName} />
    </div>
  );
};

export default CircularProgress;

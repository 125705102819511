import React, { useContext, useEffect, useState } from 'react'
import { BackButton, UserImageCard } from '../../components'
import { PiBank, PiPlusBold, PiWallet } from 'react-icons/pi'
import './mywalletpage.styles.scss'
import { FaSlidersH } from 'react-icons/fa';
import { fetchOrderDetails } from '../../utils/service';
import { useNotify } from '../../components/Toast';
import { StateContext } from '../../context/stateContext';

const MyWallet = () => {
  const [data, setData] = useState([])
  const {darkMode} = useContext(StateContext)
  const { notify } = useNotify()

  useEffect(()=>{
    fetchOrderDetails().then(res => {
      setData(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  //eslint-disable-next-line
  },[])
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
        <BackButton />
        <div className='px-20'>
            {/* Icons */}
            <div className='d-flex space-between px-10 items-center'>
                <p className='px-20 text-title fw-600'>My Wallet</p>
                <div className='d-flex position-relative'>
                  <PiBank color='gray' size={30}/>
                  <PiPlusBold color='gray' style={{position:"absolute", background:'white', right:-4, bottom:-4}} className='bank-svg'/>
                </div>
            </div>

            {/* Container */}
            <div className='mywallet-balance-container'>
              <div className='circle-1'></div>
              <div className='circle-2'></div>
              <p>My Balance</p>
              <span>3000.00</span>
            </div>

            <div style={{marginTop:10}} className='my-5 d-flex flex-end'>
              <div className='p-2 d-flex items-center border-rounded-primary'>
                <PiWallet size={20} color='var(--primary)' />
                <p className='p-2 fs-14 fw-500 text-primary '>Withdraw</p>
              </div>
            </div>

            <div className='d-flex space-between items-center'>
              <h1 className='fw-600 fs-18 dark-mode-text'>Recent Transactions</h1>
              <FaSlidersH className='slider-svg'/>
            </div>
            <br />
            {data?.map(item => {
              return(
                <UserImageCard 
                  key={item.id}
                  image={item.image}
                  priceText={(item.isRefund ? '+' : '-')+String(item.amount)}
                  fullName={item.fullName}
                  created={item.createdAt}
                />
              )
            })}
        </div>
    </div>
  )
}

export default MyWallet
import React, { useContext, useEffect, useRef, useState } from 'react'
import './guidedetailspage.styles.scss'
import { PiArrowRight, PiArticleThin, PiBook, PiBookmarkSimple, PiBookmarkSimpleFill, PiCircleFill, PiClockCountdownThin, PiCurrencyDollarFill, PiCurrencyEur, PiCurrencyInrFill, PiDotsThreeVerticalBold, PiFootball, PiGameController, PiGenderFemale, PiGenderIntersex, PiGenderMale, PiInfo, PiInstagramLogo, PiLightbulbFilament, PiLinkedinLogo, PiMapPin, PiMoney, PiPaperPlaneTiltFill, PiPhone, PiSuitcaseFill, PiTwitterLogoFill, PiUser, PiUserCheck, PiWallet, PiWarning, PiWhatsappLogoFill, PiWhatsappLogoLight, PiX } from 'react-icons/pi'
import { IoEarthOutline } from 'react-icons/io5'
import { LiaHandPointRightSolid  } from 'react-icons/lia'
import { AiOutlineStop } from 'react-icons/ai'
import { TbPhoneCheck } from 'react-icons/tb'
import { Accordion, BackButton, BasicBookingForm, BottomDrawer, ButtonComponent, Loader,  ModalContentContainer, Rating } from '../../components'
import { Facebook, Instagram, Linkedin, Youtube } from '../../assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { animated, useSpring } from '@react-spring/web'
import { useNotify } from '../../components/Toast'
import { blockUser, ChatSocket, checkIsNew, createNotification, currentChatTheme, fetchBookingDetails, GeneralSocket, getRoomName, getSocialMediaLinks, guideBookmarkStatus, guides } from '../../utils/service'
import { differenceInYears, parseISO } from 'date-fns'
import { StateContext } from '../../context/stateContext'
import { design} from '../../assets/images'
import constants from '../../constants'
import CountryFlag from 'react-country-flag';
import { useSwipeable } from 'react-swipeable';
import { CiMail, CiMobile3 } from 'react-icons/ci'

export const REPORT_MENU = [
  {id:1, title:"Inappropriate Behavior"},
  {id:1, title:"Sexual Harrasment"},
  {id:1, title:"Abusive Language"},
  {id:1, title:"Fake Account"},
  {id:1, title:"Imposter"},
]

const ChargesContainer = ({selected, setSelected, item}) => {
  return(
    <div onClick={()=>setSelected(item)} className={`cursor-pointer charges-container ${selected?.hour === item?.hour ? 'selected': ''}`}>
      <div className='mini-container'>
        <PiClockCountdownThin className='dark-mode-text' size={60}  />
        <p className='hours-text'>{item?.hour}</p>
      </div>
      <p className='text-center hour-text-1 dark-mode-text'>{item?.hour?.toLowerCase()?.split('h')[0]} Hours</p>
      <p className='price-text dark-mode-text'>INR ₹{item?.price}</p>
    </div>
  )
}

const LanguageBoxRenderer = ({language, efficiency}) => {
  return(
    <div className='lang-container'>
      {/* based on the langauge name we will render the letter here , we will need maybe dict or something */}
      <p className='first-letter'>{language?.slice(0, 1)}</p>
      <p className='lang'>{language}</p>
      <div className='badge'>
        <span className={`${efficiency?.length > 0 ? efficiency : 'good'}`}>{efficiency?.length > 0 ? efficiency : 'good'}</span>
      </div>
    </div>
  )
}

const InformationContainer = ({Icon, Icon1, title, children}) => {
  return (
      <>
        <hr />
        <div className='my-15'>

          <div className='d-flex items-center'>
              <Icon className='dark-mode-text' size={20}/>
              {Icon1 &&
                <Icon1 className='dark-mode-text' size={20}/>
              }
              <h1 className='title-text px-10 dark-mode-text'>{title}</h1>
          </div>  

          {children}
        </div>
      </>
  )
}

const GuideDetailsPage = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [contactRequested, setContactRequested] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [guideDetail, setGuideDetail] = useState({})
  const [bookingData, setBookingData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedCharge, setSelectedCharge] = useState('')
  const [showReportModal, setShowReportModal] = useState(false)
  const [reportType, setReportType] = useState('')
  const [images, setImages] = useState()
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { id , bookingId, isProfileView} = location.state || {};
  const {darkMode, userInfo} = useContext(StateContext)
  const chatSocketRef = useRef()
  const generalSocketRef = useRef()
  const notificationApiRef = useRef()
  const {notify} = useNotify()
  const slideAnimation = useSpring({
    transform: !showModal ? 'translateY(5%)' : 'translateY(0%)',
    config: { tension: 100, friction: 10 },
  });

  let timer;
  const handleCloseModal = () => {
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      if(showModal){
        setShowModal(false)
      }
    }, 50)
  }

  const notificationHandler = (msg) => {
    
    if(msg.includes('Reported')){
      if(reportType.length <= 0){
        return notify('please choose a report type')
      }
    }
    const message = msg.includes('Reported') ? `Someone ${msg}` : `${userInfo?.fullName} ${msg}`
    
    createNotification(guideDetail?.userDetails?.username, message).then(res =>{
      notify('notification sent to the user.')
      sendNotificationSocketHandler(`${message}`)
      setShowReportModal(false)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  
  const [linkInputs, setLinkInputs] = useState({
      'Facebook':'',
      'Instagram':'',
      'LinkedIn':'',
      'Youtube':'',
      'Pinterest':'',
      'Twitter':''
  })

  const sendNotificationSocketHandler = (message, type=constants.notification) => {
    GeneralSocket(guideDetail?.userDetails?.id, guideDetail?.userDetails?.username, generalSocketRef, message, type)
  }

  const loadImages = (data) => {
    const images = []
    if(data.profileImage){
      images.push(data.profileImage)
    }
    if(data.image1){
      images.push(data.image1)
    }
    if(data.image2){
      images.push(data.image2)
    }
    if(data.image3){
      images.push(data.image3)
    }
    if(data.image4){
      images.push(data.image4)
    }
    setImages(images)
  }

  useEffect(()=>{
    if(!id){
      return
    }
    getSocialMediaLinks(id).then(res=>{
      setLinkInputs({
          'Facebook':res.data?.['Facebook'] || '',
          'Instagram':res.data?.['Instagram'] || '',
          'LinkedIn':res.data?.['LinkedIn'] || '',
          'Youtube':res.data?.['Youtube'] || '',
          'Pinterest':res.data?.['Pinterest'] || '',
          'Twitter':res.data?.['Twitter'] || ''
      })
      }).catch(err =>{
      notify('Failed to get the links')
    })
    
    guides(null, id).then(res => {
      setGuideDetail(res.data)
      setIsSaved(res?.data?.bookmarked)
      loadImages(res?.data)
      if(!bookingId){
        setSelectedCharge(res.data?.charges?.[0])
      }
      if(userInfo?.username !== res.data.userDetails.username && !notificationApiRef.current){
        notificationApiRef.current = true
        createNotification(res.data?.userDetails?.username, userInfo?.fullName + ' viewed your profile')
      }
      }).catch(err => {
      notify(err.response && err.responsse.data.detail ? err.response.data.detail : err.message)
      }).finally(()=>{
        setLoading(false)
    })
    // eslint-disable-next-line
  },[id])

  useEffect(()=>{
    if(!bookingId){
      return
    }
    setLoading(true)
    fetchBookingDetails(bookingId).then(res => {
      setSelectedCharge(res.data.durations)
      setBookingData({
        "date": res.data.date,
        "time": res.data.time,
        "adults" : res.data.adults,
        "childrens" : res.data.childrens
      })
      setTimeout(()=>{
        setShowDrawer(true)
      }, 100)
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    }).finally(()=>{
      setLoading(false)
    })
  //eslint-disable-next-line
  },[bookingId])

  const blockHandler = () => {
    blockUser(guideDetail?.user).then(res => {
      notify(`User ${guideDetail?.userDetails?.fullName} is blocked.`)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
  }

  const linkHandler = (link) => {
    if(link.length > 5){
        let a = document.createElement('a')
        a.target = '_blank'
        a.href = link
        a.click()
        a.remove()
    }
  }

  const contactRequestHandler = async () => {
    sendNotificationSocketHandler(`${userInfo?.fullName} has requested for your contact`, constants.message)
    const room_name = await getRoomName(userInfo?.id, userInfo?.username, guideDetail?.userDetails?.id, guideDetail?.userDetails?.username)
    ChatSocket(room_name, userInfo?.username, `Hey , Can you please share your contact details with me ?`, chatSocketRef)
    setContactRequested(!contactRequested)
  }

  // swipe handler code
  const handleSwipe = (direction) => {
    if (direction === 'left') {
      setCurrentImageIndex((prev) => (prev + 1) % images.length);
    } else if (direction === 'right') {
      setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // To detect swipe with mouse
  });


  return (
    <div className={`${darkMode ? 'dark-mode' : ''} pt-0`}>
      {showReportModal &&
        <div className='guide-details-report-modal'>
            <div style={{ display:"flex", flexDirection:"column", justifyContent:"center"}}>
              {REPORT_MENU.map((item, index)=>{
                return(
                  <div key={index} className='cursor-pointer d-flex my-5 items-center'>
                    <input onClick={()=>setReportType(item.title)} name='a' type='radio' />
                    <p className='px-4'>{item.title}</p>
                  </div>
                )
              })}
              <div className='d-flex items-center'>
                <ButtonComponent onClick={()=>setShowReportModal(false)} style={{fontSize:'15px', padding:6}} buttonTextColor='black' title='Cancel' wrapperContainerStyle={{margin:'8px 2px', width:"50%", padding:4, borderRadius:4, backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)" }}/>
                <ButtonComponent onClick={()=>notificationHandler('Reported your profile for ' + reportType)} title='Submit' style={{fontSize:'15px', padding:5}} wrapperContainerStyle={{ margin:'8px 2px', width:"50%", padding:4, borderRadius:4,  }}/>
              </div>
            </div>
        </div>
      }
      {loading ?
        <Loader />
      :
      <div onClick={()=>handleCloseModal()}>
        
        {/* Image container */}
        <div
          {...handlers}
          className={`image-container d-flex space-between ${guideDetail?.profileImage?.includes('/media/user.png') ? 'box-shadow-inset' :''}`}
          style={{ backgroundImage: `url(${images?.[currentImageIndex]})` }}
        >
                
          {/* Back button */}
          <BackButton text='' className='cursor-pointer'/>

          {/* Three vertical dots */}
          {showModal ?
              <PiX onClick={()=>{setShowModal(!showModal);}} className={`cursor-pointer vertical-dots custom-svg svg-block ${showModal ? 'bg-white' : ''}`}/>
            :
              <PiDotsThreeVerticalBold onClick={isProfileView ? ()=>true : ()=>{setShowModal(!showModal);setShowReportModal(false)}} className={`cursor-pointer vertical-dots custom-svg ${showModal ? 'svg-block' : ''}`}/>
          }
          {/* option menu and its content */}
          <animated.div style={slideAnimation} className={`${showModal ? '' : 'hide'} modal-container`}>
            <ModalContentContainer Icon={LiaHandPointRightSolid } text='Nudge' onClick={()=>notificationHandler('nudged you.')}/>
            <ModalContentContainer Icon={isSaved ? PiBookmarkSimpleFill : PiBookmarkSimple} text={isSaved ? 'Saved Profile' : 'Save Profile'} onClick={()=>{guideBookmarkStatus(guideDetail?.id); setIsSaved(!isSaved); notify(isSaved ? 'Un-Saved Profile' : 'Saved Profile'); sendNotificationSocketHandler(`${userInfo?.fullName} ${isSaved ? 'Un-Saved Profile' : 'Saved Profile'} your profile.`)}}/>
            <ModalContentContainer color Icon={PiWarning} text='Report' onClick={()=>setShowReportModal(true)}/>
            <ModalContentContainer Icon={AiOutlineStop } text='Block' onClick={()=>blockHandler()}/>
            <ModalContentContainer Icon={PiInfo} text='Help' onClick={()=>navigate('/FAQ')}/>
          </animated.div>

          {/* status container */}
          <div className='active-status-container d-flex items-center'>
            <PiCircleFill color={guideDetail?.isActive ? 'green' : 'lightgray'} />
            <p> {guideDetail?.isActive ? 'Active' : 'InActive'} </p>
          </div>

          {/* new text  */}
          {checkIsNew(guideDetail?.createdAt) &&
            <div className='new-text-container'>
              <h1 className='new-text'> New </h1>
            </div>
          }

          {/* image dots */}
          <div className='image-dots-container'>
            {Array.from(Array(images.length).keys()).map((item, index)=>{
              const isActive = index === currentImageIndex
              return(
                <div key={index} className={`dot ${isActive ? 'active' : ''}`} />
              )
            })}
          </div>

        </div>

        <div className='information-container'>

          {/* name */}
          <h1 className='title-text dark-mode-text'>{guideDetail?.userDetails?.fullName} 
            {guideDetail?.showCountryFlag &&
                <CountryFlag  countryCode={guideDetail?.countryCode} svg style={{ marginLeft:5, marginBottom:2, width: '18px', height: '18px', borderRadius:"0% !important" }} />
            }
          </h1>

          {/* username */}
          <p className='username-text'>@{guideDetail?.userDetails?.username}</p>

          {/* gender and age  */}
          <div className='d-flex my-10'>
            <div className='d-flex items-center'>
              {guideDetail?.gender === 'Male' ?
                <PiGenderMale className='dark-mode-text' size={14}/>
                :
              guideDetail?.gender === 'Female' ?
                <PiGenderFemale className='dark-mode-text' size={14}/>
                :
              guideDetail?.gender === 'Other' ?
                <PiGenderIntersex className='dark-mode-text' size={14}/>:
                null
              }
              <p className='male-text dark-mode-text'>{!guideDetail?.gender?.includes('no-specify') ? guideDetail?.gender : "Gender not specified."}</p>
            </div>
            <p className='age-text dark-mode-text'>Age : {guideDetail?.dob && differenceInYears(new Date(), parseISO(guideDetail?.dob))}</p>
          </div>

          {/* location */}
          <div className='d-flex items-center my-10'>
            <PiMapPin className='dark-mode-text' size={14}/>
            <p className='location-text dark-mode-text'>{guideDetail?.city} , {guideDetail?.state}</p>
          </div>
          
          {/* Ratings component */}
          <div className='d-flex items-center'>
            <span onClick={isProfileView ? ()=> true : ()=>navigate('/Rate-Review', {state: {guideName:guideDetail?.userDetails?.fullName, username:guideDetail?.userDetails?.username, guideId:guideDetail?.userDetails?.id }})}><Rating rating={guideDetail?.ratings?.average_rating} /></span>
            <p style={{marginBottom:1, marginLeft:10}} className='dark-mode-text'>({guideDetail?.ratings?.totalReviewCount})</p>
          </div>

          {/* Expertise Chips */}
          <div className='d-flex flex-wrap'>
            {guideDetail?.expertise?.length > 0 &&
              <div className='d-flex items-center chips-container'>
                {guideDetail?.expertise?.map((item, index) => (
                  <p className='chips-container-text' key={index}>{item.expertise}</p>
                ))}
              </div>
            }
          </div>

          {/* person, phone, wallet : verified  */}
          <div className='verified-container'>
            <PiUserCheck onClick={guideDetail?.profileVerified ? ()=>notify('Profile Verified') : ()=>true} size={24}/>
            <TbPhoneCheck onClick={guideDetail?.contactVerified ? ()=>notify('Contact Verified') : ()=>true} strokeWidth={1.4} />
            <PiWallet onClick={guideDetail?.bankDetailsVerified ? ()=>notify('Bank Verified') : ()=>true} size={24}/>
          </div>

          {/* Bio */}
          <InformationContainer Icon={PiUser} title='Bio'>
            <p className='light-text-gd'>
              {guideDetail?.bio}
              {/* <span> Read More</span> */}
            </p>
          </InformationContainer>
  
          {/* Description */}
          <InformationContainer Icon={PiArticleThin} title='Description'>
            <p className='light-text-gd'>
              {guideDetail?.description}
              {/* <span> Read More</span> */}
            </p>
          </InformationContainer>

          {/* Itinerary */}
          {guideDetail?.itinerary?.length > 0 &&
          <>
            <hr />
            <div className='my-15 Itinerary-container'>
              {/* Text container */}
              <div className='d-flex items-center space-between'>
                <div className='d-flex items-center'>
                  <PiBook className='dark-mode-gray-text'/>
                  <h1 className='px-10 title-text dark-mode-text'>Itinerary</h1>
                </div>
                <h1 className='subtitle-text dark-mode-text'>{guideDetail?.itinerary?.length} day{guideDetail?.itinerary?.length === 1 ? '' : 's'}</h1>
              </div>
              <br/>
              <Accordion items={guideDetail?.itinerary}/>
            </div>
          </>
          }

          {/* Contact */}
          <InformationContainer Icon={PiPhone} title='Contact'>
            {guideDetail?.showMyNumber ?
              <div className='my-10'>
                <div className='my-10 d-flex items-center'>
                  <CiMail size={20} className='dark-mode-text'/>
                  <p className='px-5 dark-mode-text'>{guideDetail?.userDetails?.email}</p>
                </div>
                <div className='my-10 d-flex items-center'>
                  <CiMobile3 size={20} className='dark-mode-text'/>
                  <p className='px-5 dark-mode-text'>{guideDetail?.contact}</p>
                </div>
                <div className='my-10 d-flex items-center'>
                  <PiWhatsappLogoLight size={20} className='dark-mode-text'/>
                  <p className='px-5 dark-mode-text'>{guideDetail?.whatsappNumber}</p>
                </div>                
              </div>
            :
              <ButtonComponent onClick={()=>contactRequestHandler()} wrapperContainerStyle={{margin:'10px 0px'}} title={contactRequested ? 'Contact Requested' : 'Request for contact'} />
            }
          </InformationContainer>
          

          <hr />
          {/* Socials */}
          <>
            {/* Social media container and Contact text  */}
            <div className='mt-15 d-flex items-center'>
              {/* social media box container */}
              <div className='social-media-container-gp'>
                <PiInstagramLogo className='dark-mode-gray-text'/>
                <PiWhatsappLogoFill  className='dark-mode-gray-text'/>
                <PiTwitterLogoFill className='dark-mode-gray-text'/>
                <PiLinkedinLogo className='dark-mode-gray-text'/>
              </div>
              <h1 className='title-text dark-mode-text'>Socials</h1>
            </div>
            {/* Social media box container */}
            <div className='social-media-links'>
              <div onClick={()=>linkHandler(linkInputs?.Facebook)}>
                <img src={Facebook} alt='Facebook' />
                <p>@{guideDetail?.userDetails?.username}</p>
              </div>
              <div onClick={()=>linkHandler(linkInputs?.Instagram)}>
                <img src={Instagram} alt='Instagram' />
                <p>@{guideDetail?.userDetails?.username}</p>
              </div>
              <div onClick={()=>linkHandler(linkInputs?.Youtube)}>
                <img src={Youtube} alt='Youtube' />
                <p>@{guideDetail?.userDetails?.username}</p>
              </div>
              <div onClick={()=>linkHandler(linkInputs?.LinkedIn)}>
                <img src={Linkedin} alt='Linkedin' />
                <p>@{guideDetail?.userDetails?.username}</p>
              </div>
            </div>
          </>

          {/* Profession */}
          <InformationContainer Icon={PiSuitcaseFill} title='Profession'>
            <div className='profession-container d-flex items-center'>
              <p>{guideDetail?.profession?.length > 0 ? guideDetail?.profession : guideDetail?.isStudent ? 'Student' : ''}</p>
              <PiArrowRight />
              <p>{guideDetail?.justifyInFewWords}</p>
            </div>
          </InformationContainer>

          {/* Languages */}
          <InformationContainer Icon={IoEarthOutline } title='Language'>
            <div className='d-flex flex-wrap mb-10'>
              {guideDetail?.languages?.map((item, index)=> (
                <LanguageBoxRenderer key={index} language={item.language} efficiency={item.fluency}/>
              ))}
              {/* TODO: what about the efficiency ? */}
            </div>
          </InformationContainer>

          {/* Hobbies */}
          <InformationContainer Icon={PiGameController} Icon1={PiFootball} title='Hobbies'>
            <div className='d-flex items-center chips-container'>
              {guideDetail?.hobbiesAndInterest?.split(',')?.map((item, index)=> (
                <p key={index}>{item}</p>
              ))}
            </div>
          </InformationContainer>


          {/* Skills */}
          {guideDetail?.skills &&
            <InformationContainer Icon={PiLightbulbFilament} title='Skills'>
              <div className='d-flex items-center chips-container'>
                {guideDetail?.skills?.split(',')?.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            </InformationContainer>
          }

          <hr />
          {/* Charges */}
          <>
            {/* currency icons container and Charges text  */}
            <div className='mt-15 d-flex items-center'>
              <div className='social-media-container-gp'>
                  <PiMoney className='dark-mode-gray-text'/>
                  <PiCurrencyDollarFill className='dark-mode-gray-text'/>
                  <PiCurrencyInrFill className='dark-mode-gray-text'/>
                  <PiCurrencyEur className='dark-mode-gray-text'/>
              </div>
              <h2 className='title-text dark-mode-text'>Charges</h2>
            </div>
            {/* Charges box container */}
            <div className='d-flex items-center space-evenly flex-wrap'>
              {guideDetail?.charges?.map((item, index) => {
                return(
                  <ChargesContainer key={index} selected={selectedCharge} setSelected={setSelectedCharge} item={item}/>
                )
              })}
            </div>
          </>

          {/* Buttons */}
          {!isProfileView &&
          <>
            <ButtonComponent title='Request for personaized tour' wrapperContainerStyle={{padding:10}} onClick={()=>navigate('/Personalized-Tour', {state:{
              image:guideDetail?.profileImage,
              fullName:guideDetail?.userDetails?.fullName,
              username:guideDetail?.userDetails?.username,
              userDetailsId:guideDetail?.id,
              id:guideDetail?.userDetails?.id,
              city:guideDetail?.cityOfService,
              nickName:guideDetail?.nickName,
              isGuide:guideDetail?.isGuide
            }})}/>
            <ButtonComponent onClick={()=>navigate('/Chat', {state:{
              userDetailsId:guideDetail?.id,
              userId:guideDetail?.userDetails?.id,
              image:guideDetail?.profileImage,
              fullName:guideDetail?.userDetails?.fullName,
              username:guideDetail?.userDetails?.username,
              nickName:guideDetail?.nickName,
              isGuide:guideDetail?.isGuide,
              theme:currentChatTheme(userInfo?.chatTheme)
              }})}  Icon={PiPaperPlaneTiltFill } iconColor='var(--primary)' title='Message' wrapperContainerStyle={{padding:10, margin:"10px 0px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} buttonTextColor='var(--primary)'/>
            <ButtonComponent 
              title='Book Now' 
              wrapperContainerStyle={{padding:15, marginBottom:10, backgroundImage:`url('${design}')`, backgroundColor:"var(--primary)"}} 
              
              onClick={()=>setShowDrawer(!showDrawer)}
            />
          </>
          }
          <BottomDrawer 
            state={showDrawer}
            content={
                <BasicBookingForm
                  bookingId={bookingId}
                  bookingData={bookingData}
                  guideUsername={guideDetail?.userDetails?.username}
                  image={guideDetail?.profileImage}
                  fullName={guideDetail?.nickName || guideDetail?.userDetails?.fullName}
                  onClick={()=>setShowDrawer(!showDrawer)}
                  user1_id={userInfo?.id}  
                  user1_username={userInfo?.username}
                  guideId={guideDetail?.userDetails?.id}
                  duration={selectedCharge}
                  comingFromGDpage={true}
                />}
            yValue={100}
          />
          <br />
        </div>
      </div>
      }
    </div>
  )
}

export default GuideDetailsPage
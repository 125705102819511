import React, { useContext, useEffect, useRef, useState } from 'react'
import './personalizedtour.styles.scss'
import { PiMagicWandFill } from 'react-icons/pi'
import {BasicBookingForm, ButtonComponent, Loader} from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { StateContext } from '../../context/stateContext'
import { ChatSocket, currentChatTheme, fetchBookingDetails, getRoomName, requestPersonalizedTour } from '../../utils/service'
import { useNotify } from '../../components/Toast'


export const LabelInput = ({label, state, setState, textField, ...props}) => {
  return(
    <div className='labelAndInput-container'>
      <h2 className='dark-mode-gray-text'>{label}</h2>
      {!textField ? 
        <input {...props}/>
        :
        <textarea {...props}></textarea>
      }
    </div>
  )
}

const PersonalizedTour = () => {
  const navigate = useNavigate()
	const chatSocketRef = useRef()
  const location = useLocation()
  const { darkMode, userInfo } = useContext(StateContext)
  const { id, isGuide, image, fullName, nickName, username, city, bookingId, userDetailsId} = location.state || {};
  const [loading, setLoading] = useState(false)
  const [selectedDuration, setSelectedDuration] = useState({'hour':'3h'})
  const [bookingData, setBookingData] = useState({})
  const [data, setData] = useState({
    'specialOccasion':'',
    'bucketList':'',
    'manyOrOnePlace':'',
    'exploreHiddenGem':'',
    'withWhom':'',
    'anyCustomization':''
  })
  const { notify } = useNotify()

  const clickBasicBookingFormUsingId = () => {
    for(let key of Object.keys(data)){
      if(data[key].length === 0){
        return notify('Please fill in the empty fields.')
      }
    }

    // if(!firstWarning) {
    //   alert('are you sure you want to proceed? if yes please click the button again.')
    //   setShowFirstWarning(true)
    //   return
    // }

    const element = document.getElementById('basic-booking-form')

    if(element){
      element.click()
    }else{
      return notify('something went wrong please try again later.')
    }
  }

  const clickHandler = (basicFormId, isUpdate) => {
    requestPersonalizedTour({...data, username, basicFormId, isUpdate}).then(async res => {
      notify('request send to the guide')
      const roomName = await getRoomName(userInfo?.id, userInfo?.username, id, username)
      const message = `are you visiting ${city} for special occasion?\n<b>${data.specialOccasion}</b>\n
        what is top of your bucket list?\n<b>${data.bucketList}</b>\n
        would you prefer to see lot of places or get to know one really well?\n<b>${data.manyOrOnePlace}</b>\n
        would you like to explore hidden gems of my city?\n<b>${data.exploreHiddenGem}</b>\n
        with whom will you be joining?\n<b>${data.withWhom}</b>\n
        is there anything you"d like me to customize for your experience?\n<b>${data.anyCustomization}</b>\n
      `
      ChatSocket(roomName, userInfo?.username, message, chatSocketRef)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  useEffect(()=>{
    window.scroll(0, 0)
  },[])

  useEffect(()=>{
    if(!bookingId){
      return
    }
    setLoading(true)
    fetchBookingDetails(bookingId).then(res => {
      setSelectedDuration(res.data.durations)
      setBookingData({
        "date": res.data.date,
        "time": res.data.time,
        "adults" : res.data.adults,
        "childrens" : res.data.childrens,
      })
      setData({
        'specialOccasion':res.data.specialOccasion,
        'bucketList':res.data.bucketList,
        'manyOrOnePlace':res.data.manyOrOnePlace,
        'exploreHiddenGem':res.data.exploreHiddenGem,
        'withWhom':res.data.withWhom,
        'anyCustomization':res.data.anyCustomization
      })
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    }).finally(()=>{
      setLoading(false)
    })
  //eslint-disable-next-line
  },[bookingId])

  return (
    <div className={`${darkMode ? 'dark-mode-custom' : ""}`}>
      {loading ?
        <Loader />
        :
      <>

        {/* Header card container */}
        <p onClick={()=>navigate(-1)} className='cursor-pointer dark-mode-text' style={{position:"absolute", top:0, right:10, fontSize:30}}>&times;</p>
        <br />
        <BasicBookingForm
          bookingId={bookingId}
          bookingData={bookingData}
          guideUsername={username} 
          image={image} 
          duration={selectedDuration}
          fullName={nickName || fullName} 
          user1_id={userInfo?.id}  
          user1_username={userInfo?.username}
          guideId={id}
          requestPersonalizedTourFunction={clickHandler}
        /> 

        {/* personalize your experience text */}
        <div style={{marginTop:-10}} className='d-flex my-10 items-center px-10'>
          <PiMagicWandFill className='dark-mode-gray-text' size={20}/>
          <p className='px-5 personalize-text dark-mode-gray-text'>Let's Personalize your experience !</p>
        </div>

        {/* label & input box */}
        <div className='px-5'>
          <LabelInput value={data.specialOccasion} onChange={(e)=>setData({...data, 'specialOccasion':e.target.value})} label={`are you visiting ${city ? city : ''} for special occasion ?`}/>
          <LabelInput value={data.bucketList} onChange={(e)=>setData({...data, 'bucketList':e.target.value})} label='what is top of your bucket list ?'/>
          <LabelInput value={data.manyOrOnePlace} onChange={(e)=>setData({...data, 'manyOrOnePlace':e.target.value})} label='would you prefer to see lot of places or get to know one really well ?'/>
          <LabelInput value={data.exploreHiddenGem} onChange={(e)=>setData({...data, 'exploreHiddenGem':e.target.value})} label='would you like to explore hidden gems of my city ?'/>
          <LabelInput value={data.withWhom} onChange={(e)=>setData({...data, 'withWhom':e.target.value})} label='with whom will you be joining ?'/>
          <LabelInput value={data.anyCustomization} onChange={(e)=>setData({...data, 'anyCustomization':e.target.value})} textField={true} label='is there anything you"d like me to customize for your experience ?' placeholder='e.g. Yes! we"d like to add some local dishes to this experience.' style={{height:70}}/>
        </div>

        {/* Buttons */}
        <ButtonComponent onClick={()=>clickBasicBookingFormUsingId()} title='Request for personaized tour' wrapperContainerStyle={{margin:10, padding:10}} />
        <ButtonComponent onClick={()=>navigate('/Chat', 
          {state: {
            userDetailsId,
            userId:id,
            image:image,
            fullName,
            username,
            nickName,
            isGuide,
            theme:currentChatTheme(userInfo?.chatTheme)
          }})} iconColor='var(--primary)' title='Or just ask me a question' wrapperContainerStyle={{padding:10, margin:"0px 10px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} buttonTextColor='var(--primary)'/>
        
      </>
      }
    </div>
  )
}

export default PersonalizedTour
import React from 'react'
import './App.scss'
import Navigation from './navigation'
import StateProvider from './context/stateContext'
import { Toast } from './components'
import Footer from './pages/Footer'

const App = () => {
  return (
    <StateProvider>
      <Toast />
      <Navigation />
      <Footer />
    </StateProvider>
  )
}

export default App
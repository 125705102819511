import React from 'react';
import './modal.styles.scss';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // If the modal isn't open, return nothing

  return (
    <div className="modal-overlay">
      <div className="modal-container-m">
        <div className="modal-content">
          {children}
        </div>
        <button className="modal-close-btn" onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default Modal;

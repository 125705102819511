import React, { useContext } from 'react'
import { BackButton } from '../../components'
import { chatBackground1, chatBackground2, chatBackground3, chatBackground4 } from '../../assets/images'
import { useNotify } from '../../components/Toast'
import { updateTheme } from '../../utils/service'
import { StateContext } from '../../context/stateContext'

const BG_IMAGES = [
  {id:1, image:chatBackground1},
  {id:2, image:chatBackground2},
  {id:3, image:chatBackground3},
  {id:4, image:chatBackground4}
]

const MessagesTheme = () => {
  const {darkMode} = useContext(StateContext)
  const {notify} = useNotify()
  const updateThemeHandler = (themeType) => {
    updateTheme(themeType).then(res=>{
      notify('Chat theme updated.')      
    }).catch(err => {
      notify(err.message && err.message.data.detail ? err.message.data.detail : err.message)
    })

  }

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <BackButton size={24} text='Message Theme'/>
      <div className='d-flex space-between space-evenly items-center'>
          <div className='p-5 d-flex space-between flex-wrap'>
            {BG_IMAGES.map((item, index)=>{
              return(
                <div onClick={()=>updateThemeHandler(index+1)} className='d-flex column items-center'>
                  <img className='w-100' style={{width:140, margin:10, height:200}} src={item.image} alt='' />
                  <p className='fw-500 fs-14 dark-mode-gray-text'>Theme 1</p>
                </div>
              )
            })}
           
          </div>
      </div>
    </div>
  )
}

export default MessagesTheme
import React, { useContext, useEffect, useState } from 'react'
import '../UpdateUsername/updateusername.styles.scss'
import { StateContext } from '../../context/stateContext'
import { BackButton, ButtonComponent, OtpComponent } from '../../components'
import { fetchNumberPrivacy, updateContact, updateLocalStorage, updateNumberPrivacy } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const UpdateUsername = () => {
    const {darkMode, userInfo, setUserInfo} = useContext(StateContext)
    const [contact, setContact] = useState('')
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showUpdate, setShowUpdate] = useState(false)
    const [state, setState] = useState({
        otp1:'',
        otp2:'',
        otp3:'',
        otp4:''
    })
    const { notify } = useNotify()

    const updateHandler = ()=>{
        if(contact.length < 10){
            return notify('please enter a valid phone number')
        }
        updateContact(contact).then(res => {
            notify('Contact updated')
            setUserInfo(updateLocalStorage('contact', contact))
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

    useEffect(()=>{
        fetchNumberPrivacy().then(res => {
            setShowContact(res.data.privacyStatus)
            setTimeout(()=>{
                setLoading(false)
            }, 1000)
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(!loading){
            updateNumberPrivacy(showContact).then(res => {
                notify('Privacy status updated.')
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })
        }
        // eslint-disable-next-line
    },[showContact])

  return (
    <div className={`${darkMode ? 'dark-mode' : ''} w-100`}>
        <BackButton size={24} text='Update Contact' />
        <div className='input-container input-container-uc update-username-container w-75'>
          <span className='label'>Current Contact</span>
          <input style={{width:'75%'}} value={userInfo?.contact} disabled />
        </div>
        <div className='px-20 w-100'>
            <PhoneInput
                country={'in'}
                value={contact}
                onChange={phone => setContact(phone)}
            />
        </div>

        {/* Get Otp button */}
        <ButtonComponent onClick={() => updateHandler()} title='Get OTP' wrapperContainerStyle={{margin:"10px 20px"}}/>

        {/* otp boxes */}
        <OtpComponent state={state} setState={setState}/>

        <ButtonComponent onClick={showUpdate ? () => updateHandler() : () => setShowUpdate(true) } title={showUpdate ? 'Update' : 'Verify'} wrapperContainerStyle={{margin:"10px 20px"}}/>

        <div className='d-flex flex-end items-center px-20 my-20'>
            <p className='dark-mode-text'>Show My Number Publicly</p>
            <input checked={showContact} onChange={()=>setShowContact(!showContact)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
        </div>
    </div>
  )
}

export default UpdateUsername
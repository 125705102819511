import React, { useContext } from 'react'
import { StateContext } from '../../context/stateContext'
import { url } from '../../config'

const Footer = () => {
    const {darkMode} = useContext(StateContext)
  return (
    <div className={`${darkMode ? 'dark-mode footer-h-100' : ''}`} style={{marginTop:-3}}>
        <div className='d-flex space-evenly column px-10 my-20'>
            <div style={{borderTop:"1px solid lightgray", paddingTop:10}}>
                <p className='dark-mode-text my-5'>City2Guide</p>
                <div className='d-flex space-evenly column px-10'>
                    <p className='fs-14 my-2 dark-mode-gray-text' onClick={()=>window.open(url + '/media/files/privacy-policy.pdf', '_blank')}>Privacy Policy</p>
                    <p className='fs-14 my-2 dark-mode-gray-text' onClick={()=>window.open(url + "/media/files/do's&don'ts.pdf", '_blank')}>Do's & Dont's</p>
                    <p className='fs-14 my-2 dark-mode-gray-text' onClick={()=>window.open(url + '/media/files/privacy-policy.pdf', '_blank')}>Terms And Conditions</p>
                </div>
            </div>
            <br/>
            <p className='fs-14 text-center dark-mode-text'>@Copyright {new Date().getFullYear()}. All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer
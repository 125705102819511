import React from 'react'
import './card.styles.scss'
import RazorpayComponent from '../RazorpayComponent'

const Card = ({index, _id, promoText, promoUsed, title, desc, price, selected, setSelected}) => {
  return (
    <div onClick={()=>{setSelected(index)}} className={`card-container ${selected ? 'selected' : ''}`}>
      <div className='d-flex space-between items-center'>
        <h1 className='dark-mode-text'>{title}</h1>
        <p className='dark-mode-text'>₹{price}</p>
      </div>
      <p className='dark-mode-text'>{desc}</p>
      <div className='d-flex flex-end'>
        <div className='circle'/>
      </div>
      {selected && <RazorpayComponent setSelected={setSelected} id={index+1} membershipId={_id} isMembership={true} promoUsed={promoUsed} promoText={promoText} />}
    </div>
  )
}

export default Card
import React, { useContext, useEffect, useRef, useState } from 'react'
import './rateandreview.styles.scss'
import { user1 } from '../../assets/images'
import { PiArrowDown, PiArrowRight, PiImage, PiTwitterLogo, PiUsersThreeThin } from 'react-icons/pi'
import { animated, useSpring } from '@react-spring/web'
import { BackButton, Rating, ReviewsContainer } from '../../components'
import { useLocation } from 'react-router-dom'
import { getRateAndReviews, postRateAndReviews } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'


const LABEL_RATING_DATA = [
    {id:1, label:"Communication"},
    {id:2, label:"Punctuality"},
    {id:3, label:"Honesty & Politeness"},
    {id:4, label:"Friendliness & Fun"},
    {id:5, label:"Overall Rating"},
]

const LabelRating = ({label, rating, setRating}) => {
    return (
        <div className='my-10 label-and-rating-container'>
            <p>{label}</p>
            <div className='d-flex items-center'>
                <Rating rating={rating} setRating={setRating}/> 
                <span className='dark-mode-gray-text' style={{marginTop:2, marginLeft:10}}>{rating}/5</span>
            </div>
        </div>
    )
}

const RateAndReview = () => {
    const {darkMode} = useContext(StateContext)
    const [showAccordion, setShowAccordion] = useState(false)
    const [review, setReview] = useState('')
    const [notifier, setNotifier] = useState(false)
    const [rateAndReviews, setRateAndReviews] = useState([])
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(true)
    const [ratingData, setRatingData] = useState({
        0:0,
        1:0,
        2:0,
        3:0,
        4:0,
    })
    const location = useLocation()
    const {guideName, username, guideId} = location.state || {}
    const inputRef = useRef()
    const slideAnimation = useSpring({
        transform: showAccordion ? 'translateY(0%)' : 'translateY(-100%)',
        config: { tension: 100, friction: 15 }, // Spring-like configuration
    });
    const { notify } = useNotify()

    const postReviewHandler = () =>{
        if(review.length < 6){
            return notify("please type a review")
        }
        postRateAndReviews({...ratingData, review, image, username}).then(res =>{
            notify('Review Posted')
            setNotifier(!notifier)
            setRatingData({
                0:0,
                1:0,
                2:0,
                3:0,
                4:0,
            })
            setImage(null)
            setReview('')
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }


    useEffect(()=>{
        getRateAndReviews(guideId).then(res => {
            setRateAndReviews(res.data)
        }).catch(err=>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        }).finally(()=>{
            setLoading(false)
        })
        //eslint-disable-next-line
    },[notifier])
    
    return (
        <div className={`${darkMode ? 'dark-mode' : ''}`}>
            <div className='px-10'>
                {/* header image */}
                <BackButton />
                <div className='rate-and-review-container' />

                {/* Rate and review accordion */}
                <div className='accordion-rr '>
                    <div onClick={()=>setShowAccordion(!showAccordion)} className='d-flex items-center space-between'>
                        <div className='d-flex items-center'>
                            <img src={user1} alt='something' />
                            <h3 className='dark-mode-gray-text'>Rate & Review</h3>
                        </div>
                        {showAccordion ? 
                            <PiArrowDown />
                            :
                            <PiArrowRight />
                        }
                    </div>
                    <div className={`${showAccordion ? ''  : 'hide'}`}>
                        <hr />
                        <animated.div style={slideAnimation}>
                            {LABEL_RATING_DATA.map(({id, label}, index)=> (
                                <LabelRating setRating={(value)=>setRatingData({...ratingData, [index]:value})}  key={id} label={label} rating={ratingData[index]}/>
                            ))}
                            {image &&
                                <img className='preview-image' src={URL.createObjectURL(image)} alt='' style={{height:100, width:100}} />
                            }
                            <div className='position-relative d-flex items-center'>
                                <span className='label-text'>Write a review</span>
                                <input value={review} onChange={(e)=>setReview(e.target.value)} placeholder='How was your experience ?'/>
                                <input onChange={(e)=>setImage(e.target.files[0])} hidden ref={inputRef} type='file' />
                                <PiImage onClick={()=>inputRef?.current?.click()} size={30} color='gray' style={{position:"absolute", right:65, top:22}} />
                                <div onClick={()=>postReviewHandler()} className='button-container-rr'>
                                    <PiTwitterLogo size={30}/>
                                </div>
                            </div>
                        </animated.div>
                    </div>
                </div>
                
                {/* Guide name */}
                <h1 className='guide-name-rr dark-mode-gray-text'>{guideName}</h1>

                {/* Reviews : Icon & Text */}
                <div className='d-flex items-center'>
                    <PiUsersThreeThin className='dark-mode-gray-text' size={24} />
                    <h4 className='px-5 title-text-rr dark-mode-gray-text'>Reviews</h4>
                </div>

                {rateAndReviews.length === 0 && !loading &&
                    <p className='dark-mode-gray-text'>No reviews yet.</p>
                }

                {/* Review Container [image, name, ratings, month , year , message ] */}
                <div className='px-10'>
                    {rateAndReviews.map(item => {
                        return(
                            <ReviewsContainer data={item} key={item.id}/>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default RateAndReview
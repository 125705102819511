import React from 'react'

const ModalContentContainer = ({Icon, text, onClick=()=>true}) => {
  const color = text === 'Saved Profile' ? 'rgb(243, 1143, 0)' : text === 'Report' ? 'red' : "black"
  return (
      <div onClick={()=>onClick()} className='d-flex items-center'>
        <Icon color={color} />
        <p className='cursor-pointer' style={{color: text === 'Report' ? 'red' : "black"}}>{text}</p>
      </div>
  )
}

export default ModalContentContainer